.gar-counts-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;
}

.gar-counts-container > * {
    display: flex;
    flex-direction: row-reverse;
    
}

@media only screen and (min-width: 500px) {
    .gar-counts-container {
        flex-direction: row;
        text-align: left;
    }
}