.btn-sm {
    --btn-size: 1.5rem;
}
.btn-md {
    --btn-size: 2rem;
}
.btn-lg {
    --btn-size: 2.5rem;
}
@media only screen and (min-width: 400px) {
    .btn-sm {
        --btn-size: 2rem;
    }
    .btn-md {
        --btn-size: 2.5rem;
    }
    .btn-lg {
        --btn-size: 3rem;
    }
}

.btn {
    background-color: transparent;
    background-position: 50% 50%;
    background-size: 100% var(--btn-size);
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    font-family: var(--font-family-body);
    font-size: calc(var(--btn-size) * 0.38);
    font-weight: 600;
    height: var(--btn-size);
    text-shadow: 0px 0px 2px #ffffff;
    user-select: none;
}
.btn:disabled {
    cursor: default;
}

/* rectange */
.btn-rect {
    padding: calc(var(--btn-size) * 0.15) calc(var(--btn-size) * 0.2) calc(var(--btn-size) * 0.25) calc(var(--btn-size) * 0.2);
    width: calc(var(--btn-size) * 3);
}
.btn-rect:active {
    padding-top: calc(var(--btn-size) * 0.24);
}

/* square */
.btn-square {
    padding: calc(var(--btn-size) * 0.15) calc(var(--btn-size) * 0.1) calc(var(--btn-size) * 0.15) calc(var(--btn-size) * 0.1);
    width: var(--btn-size);
}
.btn-square:active {
    padding-top: calc(var(--btn-size) * 0.24);
}

/* rectangle default */
.btn-rect-default {
    background-image: url('/public/images/button/button-blue-3-long.png');
}
.btn-rect-default:active {
    background-image: url('/public/images/button/button-blue-3-long-pressed.png');
}
.btn-rect-default:disabled {
    background-image: url('/public/images/button/button-3-long-disabled.png');
}

/* rectangle warning */
.btn-rect-warning {
    background-image: url('/public/images/button/button-red-3-long.png');
}
.btn-rect-warning:active {
    background-image: url('/public/images/button/button-red-3-long-pressed.png');
}
.btn-rect-warning:disabled {
    background-image: url('/public/images/button/button-3-long-disabled.png');
}

/* square default */
.btn-square-default {
    background-image: url('/public/images/button/button-blue-sm-square.png');
}
.btn-square-default:active {
    background-image: url('/public/images/button/button-blue-sm-square-pressed.png');
}
.btn-square-default:disabled {
    background-image: url('/public/images/button/button-sm-square-disabled.png');
}
