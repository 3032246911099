.gs-container {
    --gs-size: .25em;
    align-items: center;
    display: flex;
    /* width: calc(var(--gs-size) * 3.8); */
}

.gs-text {
    font-family: var(--font-family-number);
    font-size: var(--gs-size);
    margin-right: 0.2em;
    text-wrap: nowrap;
}

.gs-icon {
    height: calc(var(--gs-size) * 0.7);
    width: calc(var(--gs-size) * 0.7);
}