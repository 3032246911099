.mcc-container {
    --mcc-size: .25em;
    align-items: center;
    display: flex;
}

.mcc-text {
    font-family: var(--font-family-number);
    font-size: var(--mcc-size);
    margin-right: 0.2em;
}

.mcc-icon {
    height: calc(var(--mcc-size) * 0.7);
    width: calc(var(--mcc-size) * 0.7);
}