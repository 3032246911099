.layout {
    background-image: url('/public/images/background/ocean-background.png');
    background-position: 50% 0;
    background-repeat: repeat-y;
    background-size: 200%;
    padding-top: 1rem;
    min-height: 100vh;
    width: 100%;
}

@media only screen and (min-width: 800px) {
    .layout {
        background-size: 150%;
    }
}

@media only screen and (min-width: 1200px) {
    .layout {
        background-size: 100%;
    }
}