/* 

Some styles are in the Select.js DynamicClassStyles component

*/

.select-sm {
    --select-size: 1.5rem;
}
.select-md {
    --select-size: 2rem;
}
.select-lg {
    --select-size: 2.5rem;
}
@media only screen and (min-width: 400px) {
    .select-sm {
        --select-size: 2rem;
    }
    .select-md {
        --select-size: 2.5rem;
    }
    .select-lg {
        --select-size: 3rem;
    }
}

.select {
    background-color: transparent;
    background-position: 50% 50%;
    background-size: 100% var(--select-size);
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    font-family: var(--font-family-body);
    font-size: calc(var(--select-size) * 0.38);
    font-weight: 600;
    height: var(--select-size);
    background-image: url('/public/images/select/select-red-3-long.png');
    padding: calc(var(--select-size) * 0.13) calc(var(--select-size) * 0.2) calc(var(--select-size) * 0.25) calc(var(--select-size) * 0.4);
    text-align: left;
    text-shadow: 0px 0px 2px #ffffff;
    position: relative;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
    user-select: none;
    width: calc(var(--select-size) * 3);

    & > svg {
      font-size: calc(var(--select-size) * 0.6);
      position: absolute;
      height: calc(var(--select-size) * 0.85);
      top: 0;
      right: calc(var(--select-size) * 0.05);
    }
}
  
.select-listbox {
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 6px;
    margin: 12px 0;
    min-width: 320px;
    border-radius: 12px;
    overflow: auto;
    outline: 0px;
    background: #fff;
    border: 1px solid #fff;
    color: black;

    .closed & {
      opacity: 0;
      transform: scale(0.95, 0.8);
      transition: opacity 200ms ease-in, transform 200ms ease-in;
    }
    
    .open & {
      opacity: 1;
      transform: scale(1, 1);
      transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
    }
  
    .placement-top & {
      transform-origin: bottom;
    }
  
    .placement-bottom & {
      transform-origin: top;
    }
}
  
.select-popup {
    z-index: 1;
}
  
.select-option {
    list-style: none;
    padding: 8px;
    border-radius: 8px;
    cursor: default;

    &:last-of-type {
      border-bottom: none;
    }

    &:focus-visible {
      outline: 3px solid blue;
    }
}