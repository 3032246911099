.bar {
    /* use --bar-height to adjust size, not --bar-background-size */
    --bar-height: 5rem; 
    --bar-background-size: 1em;
    font-size: var(--bar-height);
    overflow: hidden;
    position: relative;
    width: 100%;
}

.bar-background {
    display: flex;
    justify-content: space-between;
    margin: 0 calc(var(--bar-background-size) * -0.1);
}
.bar-connector {
    background-image: url('/public/images/banner/banner-horizontal-connector.png');
    background-repeat: repeat-x;
    background-size: var(--bar-background-size) var(--bar-background-size);
    width: 100%;
}
.bar-end, .bar-connector {
    height: var(--bar-background-size);
}
.bar-content-container {
    align-content: center;
    display: flex;
    height: calc(var(--bar-background-size) * 0.74);
    justify-content: space-between;
    padding: calc(var(--bar-background-size) * 0.1) calc(var(--bar-background-size) * 0.3);

    position: absolute;
    top: calc(var(--bar-background-size) * 0.03);
    width: 100%;
    z-index: 10;
}
.bar-content-item {
    align-items: center;
    /* background-image: url('/public/images/banner/banner-carved-insert-3-long.png');
    background-repeat: repeat-x;
    background-position: 50% 50%;
    background-size: 100%;
    border-radius: calc(var(--bar-background-size) * 0.02); */
    display: flex;
    justify-content: center;
}

@media only screen and (min-width: 500px) {
    .bar-background {
        margin: 0;
    }
    .bar-content-container {
        padding: calc(var(--bar-background-size) * 0.1) calc(var(--bar-background-size) * 0.4);
    }
}
